import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonBackButton,
  IonButtons,

  IonButton,
  IonIcon
} from '@ionic/react';


import './Compare.css'
import {
  chevronForwardOutline
} from 'ionicons/icons';


const Compare = (props) => {
  const { setCompareRegiao, setComparacao } = props;

  function handleClick(item) {
    // console.log("....", item );
    setComparacao(item);

    setCompareRegiao(true);
  }
  return (
    <IonPage mode="md">
      <IonHeader>
        <IonToolbar className="tituloCompare">
          <IonButtons slot="start">
            <IonBackButton defaultHref="mapa" />
          </IonButtons>
          <IonTitle className="tituloCompare">Comparar Região</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="escolhaRegiao">
          <IonLabel className="escolhaRegiao">Escolha a região para comparação</IonLabel>
        </IonGrid>
        <IonGrid className="box1" />
        {
          window.compareArray && window.compareArray.map((item, i) => (
            <div key={i}>
              <IonGrid className="box1">
                <IonLabel className="texto1">{item.nome}</IonLabel>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton onClick={() => handleClick(item)}>
                      <IonIcon icon={chevronForwardOutline} />
                    </IonButton>
                  </IonButtons>
                  <IonLabel className="texto2">  GERENCIADOS: <IonGrid className="texto3">{item.gerenciado.toLocaleString('pt-BR')}</IonGrid></IonLabel><br />
                  <IonLabel className="texto2">IMPLANTADOS: <IonGrid className="texto3">{item.implantado.toLocaleString('pt-BR')}</IonGrid></IonLabel>
                </IonToolbar>
              </IonGrid>
            </div>
          ))
        }
      </IonContent>
    </IonPage>
  );
};

export default Compare;
