export function DadosConsolidados(json) {
    let ret = {};
    let o = {
      uf: "Distrito Federal",
      geren: 0,
      impleme: 0,
      consedida: 0,
      edificada: 0,
      aSerEdificar: 0,
      investimentos: 0,
      valoresImoveis: 0,
      descontoConsedido: 0,
      atuais: 0,
      aSeremGerados: 0,
      total: 0
    }
    json.forEach(item => {
      // debugger;
      if (typeof item["__4"] !== 'string') {
        o.geren += item["__4"]
      }
      if (typeof item["__5"] !== 'string') {
        o.impleme += item["__5"]
      }
      if (item["__6"].trim().length > 0) {
        let val = parseFloat(item["__6"].replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.consedida += val;
        }
      }
      if (item["__7"].trim().length > 0) {
        let val = parseFloat(item["__7"].replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.edificada += val;
        }
      }
      if (item["__8"].trim().length > 0) {
        let val = parseFloat(item["__8"].replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.aSerEdificar += val;
        }
      }
      if (item["__9"].trim().length > 0) {
        let val = parseFloat(item["__9"].replace("R$ ", '').replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.investimentos += val;
        }
      }
      if (item["__10"].trim().length > 0) {
        let val = parseFloat(item["__10"].replace("R$ ", '').replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.valoresImoveis += val;
        }
      }
      if (item["__11"].trim().length > 0) {
        let val = parseFloat(item["__11"].replace("R$ ", '').replace(/\./g, '').replace(",", '.'));
        if (val > 0) {
          o.descontoConsedido += val;
        }
      }
      if (typeof item["__12"] !== 'string') {
        o.atuais += item["__12"]
      }
      if (typeof item["__13"] !== 'string') {
        o.aSeremGerados += item["__13"]
      }
      if (typeof item["__14"] !== 'string') {
        o.total += item["__14"]
      }
    });
    ret.empreendimentos = o;
    return ret;
}