
import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonGrid,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
  IonLabel,
} from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import './Lista.css';


export default function Lista(props) {
  let { regiaoADE } = props;
  const [regiaoS, setRegiaoS] = useState();
  const [dadoFiltrado, setDadoFiltrado] = useState();

  useEffect(() => {
    if (regiaoADE) {
      regiaoSelicionada(regiaoADE);
    } else {
      setDadoFiltrado(window.dado);
    }
  }, [regiaoADE])
  const handleChange = (e) => {
    makeFiltro(e.detail.value);
  }

  function makeFiltro(filtro) {
    const arr = window.dado.filter(item => {
      if (item["__3"].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filtro.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
        return true;
      }
      return false;
    })
    setDadoFiltrado(arr);
  }

  function regiaoSelicionada(selecionado) {
    const arr = window.dado.filter(item => {
      if (item["__3"] === selecionado) {
        return true;
      }
      return false;
    })
    setRegiaoS(arr);
    setDadoFiltrado('')
  }
  return (
    <IonContent mode="md">
      <IonHeader collapse="condense">
        <IonToolbar mode="md">
          <IonTitle size="large" mode="md">Lista</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToolbar>
        <IonSearchbar placeholder="Informe a região" onIonChange={handleChange}></IonSearchbar>
      </IonToolbar>
      <IonList >
        {dadoFiltrado && dadoFiltrado.map((item, i) => (
          <IonButtons className="campo3" key={i} slot="start" mode="md">
            <IonButton className="campo2" onClick={() => regiaoSelicionada(item['__3'])}  mode="md">
              <IonIcon icon={chevronDown} />
              {item['__3']}
            </IonButton>
          </IonButtons>
        ))}
      </IonList>

      {regiaoS && regiaoS.map((item, i) => (
        <IonList key={i} mode="md" >
          <IonToolbar className="tituloPage" mode="md">
            <IonTitle className="Titulos1" mode="md">
              <IonLabel className="Titulos1A" mode="md">  Resultado: {item['__1']} - {item['__3']}</IonLabel>

            </IonTitle>
          </IonToolbar>


          <IonGrid className="fundoColor" mode="md">

            <IonTitle className="Titulos">EMPREEDIMENTOS</IonTitle>

            <IonRow className="subtitulos" mode="md">
              <IonCol className="Texto5">UF</IonCol>
              <IonCol className="Texto5">GERENCIADOS</IonCol>
              <IonCol className="Texto5">IMPLANTADOS</IonCol>
            </IonRow>

            <IonRow className="textoNum" mode="md">
              <IonCol className="Numero">{item["__1"]}</IonCol>
              <IonCol className="Numero">{item["__4"].toLocaleString('pt-BR')}</IonCol>
              <IonCol className="Numero">{item["__5"].toLocaleString('pt-BR')}</IonCol>
            </IonRow>

          </IonGrid>

          <IonGrid className="fundoColor" mode="md">
            <IonTitle className="Titulos" >EMPREEDIMENTOS ÁREA M²</IonTitle>
            <IonRow className="subtitulos" mode="md">
              <IonCol className="Texto5" >CONCEDIDA</IonCol>
              <IonCol className="Texto5" >EDIFICADA</IonCol>
              <IonCol className="Texto5" >A SER EDIFICADA</IonCol>
            </IonRow>
            <IonRow className="textoNum" key={i}  mode="md">
              <IonCol className="Numero">{item["__6"]}</IonCol>
              <IonCol className="Numero">{item["__7"]}</IonCol>
              <IonCol className="Numero">{item["__8"]}</IonCol>
            </IonRow>
          </IonGrid >
          <IonGrid className="fundoColor" mode="md" mode="md">
            <IonTitle className="Titulos" mode="md">INVESTIMENTOS E VALORES</IonTitle>
            <IonRow className="subtitulos" mode="md">
              <IonCol className="Texto5" >INVESTIMENTOS</IonCol>
              <IonCol className="Texto5">VAL. DOS IMÓVEIS</IonCol>
              <IonCol className="Texto5">DESCONTOS</IonCol>
            </IonRow>
            <IonRow className="textoNum" key={i} mode="md" mode="md">
              <IonCol className="Numero">R$ {item["__9"]}</IonCol>
              <IonCol className="Numero">R$ {item["__10"]}</IonCol>
              <IonCol className="Numero">R$ {item["__11"]}</IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid className="fundoColor" mode="md">
            <IonTitle className="Titulos">EMPREGOS GERADOS</IonTitle>
            <IonRow className="subtitulos">
              <IonCol className="Texto5">ATUAIS</IonCol>
              <IonCol className="Texto5">A SEREM GERADOS</IonCol>
              <IonCol className="Texto5">TOTAL</IonCol>
            </IonRow>
            <IonRow className="textoNum" key={i} mode="md">
              <IonCol className="Numero">{item["__12"].toLocaleString('pt-BR')}</IonCol>
              <IonCol className="Numero">{item["__13"].toLocaleString('pt-BR')}</IonCol>
              <IonCol className="Numero">{item["__14"].toLocaleString('pt-BR')}</IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      ))}
    </IonContent>

  );
}
