import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonLabel,
  IonIcon
} from '@ionic/react';
// import { Chart } from 'chart.js'
import './CompareRegiao.css'
import { arrowBackOutline } from 'ionicons/icons';
import { Bar } from 'react-chartjs-2'
// import Compare from './Compare'
import { FormataMoeda } from "../Controller";

let total;
export default function CompareRegiao(props) {

  const { comparacao, setCompareRegiao } = props;
  const [dado, setDado] = useState();

  useEffect(() => {
    total = JSON.parse(localStorage.getItem("compareTotal"));


    // console.log('>>>comparacao<<<', comparacao, total);

    setDado({
      type: "bar",
      options: {
        options: {
          legend: {
            display: false
          },
          scales: {
            // xAxes: [{
            //   categoryPercentage: 1.0,
            //   barPercentage: 0.2,
            //   barThickness: 10,
            //   maxBarThickness: 10,
            //   minBarLength: 5,
            //   gridLines: {
            //     drawOnChartArea: false,
            //     offsetGridLines: true
            //   }
            // }],
            // yAxes: [{
            //   gridLines: {
            //     offsetGridLines: false
            //   },
            //   ticks: {
            //     beginAtZero: true,
            //     max: 30
            //   }
            // }]
          }
        }
      },

      labels: [
        'Gerenciados/10',
        'Implantados',
        'Área concedida/10m',
        'Área Edificada m²/m',
        'Investimentos/100m',
        'Empregos Atuais/100',
        'Total de Empregos'
      ],
      datasets: [
        {
          fill: true,
          label: comparacao.nome,
          backgroundColor: "#4079BC",

          data: [
            (comparacao.gerenciado / 10),
            comparacao.implantado,
            (comparacao.concedida / 10000),
            (comparacao.edificada / 1000),
            (comparacao.investimento / 1000000),
            comparacao.empregoAtual / 100,
            comparacao.empregoTotal / 100,
          ]

        },
        {
          fill: true,
          label: "Pro-DF",
          backgroundColor: "#45B97C",

          data: [
            (total.gerenciado / 10),
            total.implantado,
            (total.concedida / 10000),
            (total.edificada / 1000),
            (total.investimento / 1000000),
            total.empregoAtual / 100,
            total.empregoTotal / 100,
          ]
        }
      ]
    });
  }, [comparacao]);

  return (
    // Parte de cima
    <IonPage mode="md">
      <IonHeader>
        <IonToolbar className="titulo">
          <IonButtons slot="start" onClick={() => setCompareRegiao(false)} >
            <IonButton>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle className="titulo">Comparar Região</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent mode="md" >


        <IonGrid className="campo1" mode="md">
          <IonGrid mode="md">
            <IonLabel className="text1">{comparacao.nome}</IonLabel>
          </IonGrid>
          <IonGrid mode="md">
            <IonLabel className="text2"> PRO-DF<br /> DESENVOLVE- DF</IonLabel>
          </IonGrid>

        </IonGrid>
        <IonGrid className="grafico" mode="md">
          <div className="fixed" scrolly="true" >
            {dado &&
              <div className="chartAreaWrapper" id="myFixZone">
                <Bar
                  mode="md"
                  className="graficos"
                  data={dado}
                  options={{
                    maintainAspectRatio: false,
                    tooltips: {
                      enabled: false
                    },
                    animation: {
                      onComplete: function () {
                        var ctx = this.chart.ctx;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        var chart = this;
                        var datasets = this.config.data.datasets;

                        datasets.forEach(function (dataset, i) {
                          // ctx.font = "12px Lobster Two";
                          ctx.fillStyle = "#4F4C4D";
                          chart.getDatasetMeta(i).data.forEach(function (p, j) {
                            ctx.fillText(
                              parseInt(datasets[i].data[j]),
                              p._model.x, p._model.y - 5);
                          });
                        });
                      }
                    },
                    legend: {
                      display: false
                    },
                    scales: {
                      yAxes: [{
                        ticks: {
                          display: false
                        }
                      }]
                    }
                  }}
                />

              </div>
            }
            <div className='a'>Fonte: Secretaria de Estado de Empreendedorismo - SEMP</div><br />
            <span className="dotBlue"></span><div className='a'> {comparacao.nome}</div><br />
            <span className="dotGreen"></span><div className='a' >Pro-DF / Desenvolve-DF</div>

          </div>
        </IonGrid>

        {total &&
          <IonGrid className="campoDados" mode="md">

            <IonGrid mode="md">
              <IonLabel className="text"> Empreendimentos Gerenciados</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.gerenciado.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{parseInt((comparacao.gerenciado * 100) / total.gerenciado)}%</IonLabel>
              <IonLabel className="text2">{total.gerenciado.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid mode="md" >
              <IonLabel className="text"> Empreendimentos Implantados</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.implantado.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{parseInt((comparacao.implantado * 100) / total.implantado)}%</IonLabel>
              <IonLabel className="text2">{total.implantado.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid mode="md">
              <IonLabel className="text"> Área Concedida m²</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.concedida.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{parseInt((comparacao.concedida * 100) / total.concedida)}%</IonLabel>
              <IonLabel className="text2">{total.concedida.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid mode="md" >
              <IonLabel className="text"> Área Edificada m²</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.edificada.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.edificada * 100) / total.edificada))}%</IonLabel>
              <IonLabel className="text2">{total.edificada.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid mode="md">
              <IonLabel className="text">Área a ser edificada m²</IonLabel>
            </IonGrid>

            <IonGrid className="campo1">
              <IonLabel className="text1">{comparacao.aSerEdificada.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.aSerEdificada * 100) / total.aSerEdificada))}%</IonLabel>
              <IonLabel className="text2">{total.aSerEdificada.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid mode="md">
              <IonLabel className="text">Investimentos R$</IonLabel>
            </IonGrid>

            <IonGrid className="campo1">
              <IonLabel className="text1">{FormataMoeda(comparacao.investimento)}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.investimento * 100) / total.investimento))}%</IonLabel>
              <IonLabel className="text2">{FormataMoeda(total.investimento)}</IonLabel>
            </IonGrid>

            <IonGrid >
              <IonLabel className="text">Valores dos imóveis R$</IonLabel>
            </IonGrid>

            <IonGrid className="campo1">
              <IonLabel className="text1">{FormataMoeda(comparacao.vImovel)}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.vImovel * 100) / total.vImovel))}%</IonLabel>
              <IonLabel className="text2">{FormataMoeda(total.vImovel)}</IonLabel>
            </IonGrid>

            <IonGrid >
              <IonLabel className="text">Empregos gerados atualmente  </IonLabel>
            </IonGrid>

            <IonGrid className="campo1">
              <IonLabel className="text1">{comparacao.empregoAtual.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.empregoAtual * 100) / total.empregoAtual))}%</IonLabel>
              <IonLabel className="text2">{total.empregoAtual.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

            <IonGrid >
              <IonLabel className="text">Empregos a serem gerados</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.aSerGerado.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.aSerGerado * 100) / total.aSerGerado))}%</IonLabel>
              <IonLabel className="text2">{total.aSerGerado.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>
            <IonGrid mode="md">
              <IonLabel className="text">Total de Empregos</IonLabel>
            </IonGrid>

            <IonGrid className="campo1" mode="md">
              <IonLabel className="text1">{comparacao.empregoTotal.toLocaleString('pt-BR')}</IonLabel>
              <IonLabel className="text">{(parseInt((comparacao.empregoTotal * 100) / total.empregoTotal))}%</IonLabel>
              <IonLabel className="text2">{total.empregoTotal.toLocaleString('pt-BR')}</IonLabel>
            </IonGrid>

          </IonGrid>
        }
      </IonContent>
    </IonPage>
  );
};
