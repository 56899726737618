import React, {useRef} from 'react';
import {
  IonPage,
  IonSlides,
  IonSlide,
  IonContent,
  IonImg,
  IonButton,
  IonGrid,
  IonCol,
  IonRow
} from '@ionic/react';
import './Introducao.css';
import Imagem1 from '../imagens/Page1.png';
import Imagem2 from '../imagens/Page2.png';
import Imagem3 from '../imagens/Page3.png';

export default function Introducao(props) {

  const { setIntroducao } = props;
  const slides = useRef(null);

  const handleClick = () => {
    slides.current.getActiveIndex().then(index => {
      if( index >=2 ){
        let info = JSON.parse(localStorage.getItem("info"));
        info = (info)?info:{};
        info.introducao=true;
        localStorage.setItem("info", JSON.stringify(info) );
        setIntroducao(false);
      }
    });
    slides.current.slideNext();
  }

  return (
    <IonPage mode="md" >
      <IonContent >
        <IonSlides pager={true} className="silidePrimeiro" ref={slides} >
          <IonSlide > 
            <IonGrid >
              <IonCol>
                <IonImg src={Imagem1} className="img1" />
              </IonCol>
              <IonCol className="conteudo">
                <h1 className="titulosPages" >Explore</h1>

                Pesquise por indicadores dos programas Pro-df
                e Desenvolver-Df nas regiões Administrativas do
                Distrito Federal.<br />

                <IonButton color="success" shape="round" onClick={handleClick}>Iniciar</IonButton>
              </IonCol>
            </IonGrid>
          </IonSlide>
          <IonSlide>  {/* /////Page2\\\\\ */}
            <IonGrid >
              <IonCol>
                <IonImg src={Imagem2} className="img2" />
              </IonCol>
              <IonCol className="conteudo">
                <h1 className="titulosPages" >Compare</h1>
                Veja os indicadores e o quanto já foi investido em sua região. <br />
                <IonButton color="success" shape="round" onClick={handleClick}>Iniciar</IonButton>
              </IonCol>
            </IonGrid>
          </IonSlide>
          <IonSlide>  {/* /////Page3\\\\\ */}
            <IonRow>
              <IonGrid size="60" >
                <IonCol>
                  <IonImg src={Imagem3} className="img3" />
                </IonCol>
                <IonCol className="conteudo">
                  <h1 className="titulosPages" >Acompanhe</h1>
                  Seja participativo, acompanhe as notícias e os
                  indicadores de empregos e investimentos. <br />
                  <IonButton color="success" shape="round" onClick={handleClick}>Iniciar</IonButton>
                </IonCol>
              </IonGrid>
            </IonRow>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
