import React, { useRef } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonLabel, IonButtons, IonBackButton, IonImg, IonIcon, IonButton, IonFabButton, IonAlert, IonSlides, IonSlide, IonCol } from '@ionic/react';
// import styled from 'styled-components'
import './SobreNos.css';
import { useState } from 'react';
import { logoFacebook, logoYoutube, logoInstagram } from 'ionicons/icons';
import Ouvidoria from '../imagens/OuvidoriaLogo.png';
import Image1 from '../imagens/image1.png'
import Image2 from '../imagens/image2.png'
import Image3 from '../imagens/image3.png'
// import { Link } from 'react-router-dom';
import MarcaHorizontal from '../imagens/MarcaHorizontal.png';


const SobreNos = (props) => {
  const slides = useRef(null);

  const [showAlert1, setShowAlert1] = useState({

  });



  return (
    <IonPage mode="md">
      <IonHeader>
        <IonToolbar className="titulo">
          <IonButtons slot="start">
            <IonBackButton defaultHref="mapa" />
          </IonButtons>
          <IonTitle className="titulo">Sobre a Secretaria</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <IonGrid className="planoFundo">

          <IonGrid className="PlanoNoticia">
            <IonGrid>
              <IonImg className="imgGdf" src={MarcaHorizontal} />
              <p className="secretaria">Secretaria de Estado de<br /> Empreendedorismo</p>
            </IonGrid>
          </IonGrid>

          <IonGrid className="PlanoServicos">
            <IonSlides pager={true} ref={slides} >

              <IonSlide >
                <IonGrid className="espaço1" >
                  <IonGrid className="imagem-campo">
                    <IonImg className="imagem1" src={Image1} />
                  </IonGrid>
                  <IonCol >

                    <IonGrid className="campo-texto">
                      <IonLabel>Programas de Desenvolvimento</IonLabel>
                    </IonGrid>
                    <IonGrid className="campo-subtexto">
                      <IonLabel>DESENVOLVE-DF, PRO-DF II, PRÓ-DF, PADES, PRODECON e PROIN</IonLabel>
                    </IonGrid>
                    <IonGrid className="botao-acesse">
                      <IonButton className="botao-acesse" expand="block" color="success" shape="round" href="http://www.empreender.df.gov.br/programas-de-desenvolvimento/">Acesse</IonButton><br /><br />
                    </IonGrid>

                  </IonCol>
                </IonGrid>
              </IonSlide>

              <IonSlide >
                <IonGrid className="espaço1" >
                  <IonGrid className="imagem-campo">
                    <IonImg className="imagem2" src={Image2} />
                  </IonGrid>
                  <IonCol >

                    <IonGrid className="campo-texto">
                      <IonLabel>Unidade de Apoio à Mulher Empreendedora</IonLabel>
                    </IonGrid>
                    <IonGrid className="campo-subtexto">
                      <IonLabel>Desenvolver o crescimento pessoal e profissional das mulheres empreendedoras com troca de</IonLabel>
                    </IonGrid>
                

                    <IonGrid className="botao-acesse">
                      <IonButton className="botao-acesse" expand="block" color="success" shape="round" href="http://www.empreender.df.gov.br/unidade-de-apoio-a-mulher-empreendedora/">Acesse</IonButton><br /><br />
                    </IonGrid>

                  </IonCol>
                </IonGrid>
              </IonSlide>

              <IonSlide >
                <IonGrid className="espaço1" >
                  <IonGrid className="imagem-campo">
                    <IonImg className="imagem3" src={Image3} />
                  </IonGrid>
                  <IonCol >
                  <IonGrid className="campo-texto">
                      <IonLabel>Simplifica PJ</IonLabel>
                    </IonGrid>
                    <IonGrid className="campo-subtexto">
                      <IonLabel>Espaço único e centralizado que garanta a celeridade, eficiência e qualidade no atendimento às demandas</IonLabel>
                    </IonGrid>

                    <IonGrid className="botao-acesse">
                      <IonButton className="botao-acesse" expand="block" color="success" shape="round" href="http://www.empreender.df.gov.br/simplifica-pj/">Acesse</IonButton><br /><br />
                    </IonGrid>

                  </IonCol>
                </IonGrid>
              </IonSlide>

            </IonSlides>
          </IonGrid>

          <IonGrid className="PlanoSocial">
            <IonLabel className="socialText">Acompanhe nossas redes sociais</IonLabel>
            <IonGrid className="social">
              <IonGrid className="social">
                <IonButtons>

                  <IonGrid >
                    <IonFabButton href="https://www.facebook.com/dfempreender/" size={"small"}>
                      <IonIcon icon={logoFacebook} />
                    </IonFabButton>
                  </IonGrid>

                  <IonGrid>
                    <IonFabButton href="https://www.youtube.com/channel/UCnUIezU31m9dYSyztQ2DDIg?view_as=subscriber" size={"small"}>
                      <IonIcon icon={logoYoutube} />
                    </IonFabButton>
                  </IonGrid>

                  <IonGrid>
                    <IonFabButton href="https://www.instagram.com/empreender_df/" size={"small"}>
                      <IonIcon icon={logoInstagram} />
                    </IonFabButton>
                  </IonGrid>



                </IonButtons>
              </IonGrid>


            </IonGrid>
          </IonGrid>
          <IonGrid className="PlanoSecretaria">

            <IonLabel className="socialText">Fale com a Secretaria</IonLabel>

            <IonLabel className="Texto2">Telefone: 2141-5434</IonLabel>

            <IonLabel className="Texto2"> E-mail: <IonLabel className="socialText"> ascom.semp@semp.df.gov.br </IonLabel></IonLabel>

            <IonLabel className="Texto2">Chefe da Assessoria Flávia Duarte</IonLabel><br />

            <IonLabel className="socialText">Horário de Atendimento ao Público</IonLabel>

            <IonLabel className="Texto2">12:00 às 18:00</IonLabel><br />

            <IonLabel className="socialText">Ouvidoria</IonLabel>
            <IonGrid className="ouvidoria">
              <IonLabel className="Texto2">Se você deseja enviar sugestões, críticas, denúncias, elogios, reclamações, entre em contato.</IonLabel>

            </IonGrid>
            <IonGrid>
              <IonImg src={Ouvidoria} className="OuvidoriaLogo" />
            </IonGrid>
            <br />

            <IonButton
              className="botao"
              color="success"
              shape="round"
              onClick={() => setShowAlert1(true)} expand="block" >Fale com a Ouvidoria</IonButton>
            <IonAlert
              isOpen={showAlert1}
              onDidDismiss={() => setShowAlert1(false)}
              cssClass='my-custom-class'
              header={'ATENÇÃO!'}
              message={'Vocẽ será direcionado para o seu navegador padrão!'}
              buttons={[
                {
                  text: 'CANCELAR',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: blah => {
                    // console.log('Confirm Cancel: blah');
                  }
                },
                {
                  text: 'OK',

                  handler: () => {
                    window.location.href = "https://www.ouv.df.gov.br/#/";
                    // console.log('Confirm Okay');
                  }
                }
              ]}
            />
          </IonGrid>
        </IonGrid>



      </IonContent>
    </IonPage>
  );
};

export default SobreNos;
