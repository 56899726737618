import React, { useState } from 'react';
import {
  IonPage,
  IonIcon,
  IonHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton
} from '@ionic/react'
import Mapa from './Mapa.js'
import Lista from './Lista'
import { map, list } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';
import './TabMapa.css';

export default function TabMapa(props) {
  const { lat, lng, setLocal, local, setPesquisarRegiao, serListaRA, localPonto, setLocalPonto } = props;
  const [comuta, setComuta] = useState(true);
  const [regiaoADE, setRegiaoADE] = useState('');

  function setLista() {
    // console.log('>>window.dadoConsolidado', window.dadoConsolidado );
    setComuta(false);
    setRegiaoADE('');
  }
  function setMapa() {
    setComuta(true);
  }
  return (
    <IonPage mode="md">
      <IonReactRouter>
        <IonHeader className="cabecalho" >
          <IonSegment>
            <IonSegmentButton mode="md" layout="icon-start" onClick={() => setMapa()} className="botaoCabec" >
              <IonIcon icon={map} />
              <IonLabel>Mapa</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton mode="md" layout="icon-start" onClick={() => setLista()} className="botaoCabec" >
              <IonIcon icon={list} />
              <IonLabel>Lista</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonHeader>
      </IonReactRouter>
      {comuta &&
        lat && lng &&
        <Mapa
          lat={lat}
          lng={lng}
          setLocal={setLocal}
          local={local}
          setPesquisarRegiao={setPesquisarRegiao}
          setComuta={setComuta}
          setRegiaoADE={setRegiaoADE}
          localPonto={localPonto}
          setLocalPonto={setLocalPonto}
        />
      }
      {!comuta &&
        <Lista serListaRA={serListaRA} regiaoADE={regiaoADE} setRegiaoADE={setRegiaoADE} />
      }
    </IonPage>
  )
}
