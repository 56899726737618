import React, { useState, useEffect } from 'react';
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    // IonBackButton,
    IonTitle,
    IonContent,
    IonSearchbar,
    IonIcon,
    IonList,
    IonGrid,
    IonLabel
} from '@ionic/react';
import { arrowBackOutline, chevronBackOutline } from 'ionicons/icons';
import './PesquisarRegiao.css'

export default function PesquisarRegiao(props) {
    const { setPesquisarRegiao, setLocalPonto } = props;
    const [dadoFiltrado, setDadoFiltrado] = useState();
    useEffect(() => {
        setDadoFiltrado(window.dado);
        // console.log( window.dado );
    }, [])
    const handleChange = (e) => {
        const arr = window.dado.filter(item => {
            // console.log(item["__3"]);
            if (item["__3"].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(e.detail.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) return true;
            return false;
        })
        setDadoFiltrado(arr);
    }
    function clickItem(a) {
        // console.log("<<<<<<to map locate>>>>>>>");
        setPesquisarRegiao(false);
        setLocalPonto(a);
    }
    return (
        <IonPage mode="md">
            <IonHeader>
                <IonToolbar className="titulo">
                    <IonButtons slot="start" onClick={() => setPesquisarRegiao(false)}>
                        <IonButton>
                            <IonIcon icon={arrowBackOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="titulo">Pesquisar Região</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonToolbar>
                    <IonSearchbar placeholder="Informe a região desejada" onIonChange={handleChange}></IonSearchbar>
                </IonToolbar>
                <IonList>
                    <IonGrid className="resultado">Resultados: </IonGrid>
                    {dadoFiltrado && dadoFiltrado.map((item, i) => (

                        <IonButtons className="campos1" key={i} slot="start">
                            <IonButton className="campos2" onClick={() => clickItem(item['__3'])}>
                                <IonLabel className="campos2">
                                    <IonIcon icon={chevronBackOutline} />
                                    {item['__3']}
                                </IonLabel>
                            </IonButton>
                        </IonButtons>

                    ))}
                </IonList>
            </IonContent>
        </IonPage >
    );
};
