import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { Plugins } from "@capacitor/core";
import { IonReactRouter } from '@ionic/react-router';
import { home, search, newspaper, people } from 'ionicons/icons';
import { CriarCompare } from "./Controller";
import { DadosConsolidados } from "./CalculosCons";
import './App.css';

import TabMapa from './pages/TabMapa';
import Compare from './pages/Compare';
import SobreNos from './pages/SobreNos';
import Noticia from './pages/Noticia';
import TelaInicial from "./pages/TelaInicial";
import Introducao from "./pages/Introducao";
import PesquisarRegiao from "./pages/PesquisarRegiao";
import CompareRegiao from "./pages/CompareRegiao";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './index.css'

let primeiro = true;
let lat;
let lng;
var zoomMap = 13;

window.dado = {};

const App = () => {
  const [splashScreen, setSplashScreen] = useState(true);
  const [introducao, setIntroducao] = useState(false);
  const [local, setLocal] = useState(false);
  const [pesquisarRegiao, setPesquisarRegiao] = useState(false);
  const [compareRegiao, setCompareRegiao] = useState(false);
  const [localPonto, setLocalPonto] = useState('')



  const [comparacao, setComparacao] = useState();

  useEffect(() => {
    if (primeiro) {
      // setTimeout(function () { setSplashScreen(false); }, 1000);
      let info = JSON.parse(localStorage.getItem("info"));
      if (!info || !info.introducao) setIntroducao(true);
      getCurrentLocation();
    }

  }, []);

  function getCurrentLocation() {
    Plugins.Geolocation.getCurrentPosition().then(result => {
      lat = result.coords.latitude;
      lng = result.coords.longitude;
      zoomMap = 13;
      buscaDados();

    }).catch((error) => {
      console.log(error)
      lat = -15.790619;
      lng = -47.914349;
      zoomMap = 13;
      console.log('Error getting location', error);
      buscaDados();
    });;
  }
  function buscaDados() {

    fetch("/dado.json")
      .then(response => response.json())
      .then(json => {
        window.dado = json;
        window.dadoConsolidado = DadosConsolidados(json);
        CriarCompare(json);
        // setSplashScreen(false);
        setTimeout(function () { setSplashScreen(false); }, 3000);
      });

  }
  if (splashScreen) {
    return <TelaInicial />;
  } else if (introducao) {
    return <Introducao setIntroducao={setIntroducao} />;
  } else if (pesquisarRegiao) {
    return <PesquisarRegiao setPesquisarRegiao={setPesquisarRegiao} setLocalPonto={setLocalPonto} />
  } else if (compareRegiao) {
    return <CompareRegiao setCompareRegiao={setCompareRegiao} comparacao={comparacao} />
  }
  return (

    <IonApp mode="md">

      <IonReactRouter mode="md">
        <IonTabs mode="md">
          <IonRouterOutlet mode="md">

            <Route path="/compare"> <Compare setCompareRegiao={setCompareRegiao} setComparacao={setComparacao} /> </Route>
            <Route exact path="/mapa" > <TabMapa
              lat={lat}
              lng={lng}
              zoomMap={zoomMap}
              setLocal={setLocal}
              local={local}
              setPesquisarRegiao={setPesquisarRegiao}
              localPonto={localPonto}
              setLocalPonto={setLocalPonto}
            /> </Route>
            <Route path="/sobreNos" component={SobreNos} />
            <Route path="/noticia" component={Noticia} />

            <Route path="/" render={() => <Redirect to="/mapa" />} exact={true} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" mode="md" className="bar-botton">
            <IonTabButton tab="mapa" href="/mapa" mode="md" className="bar-botton">
              <IonIcon icon={home} />
              <IonLabel mode="md" className="home">Home</IonLabel>
            </IonTabButton>

            <IonTabButton tab="compare" href="/compare" mode="md" className="bar-botton">
              <IonIcon icon={search} />
              <IonLabel mode="md" className="home">Compare</IonLabel>
            </IonTabButton>

            <IonTabButton tab="noticia" href="/noticia" mode="md" className="bar-botton">
              <IonIcon icon={newspaper} />
              <IonLabel mode="md" className="home">Notícias</IonLabel>
            </IonTabButton>

            <IonTabButton tab="sobreNos" href="/sobreNos" mode="md" className="bar-botton">
              <IonIcon icon={people} />
              <IonLabel mode="md" className="home">Sobre Nós</IonLabel>
            </IonTabButton>

          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
