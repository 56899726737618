import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonButtons, IonBackButton } from '@ionic/react';

import './Noticia.css';

const Noticia = () => {
  return (
    <IonPage mode="md">
      <IonHeader>
        <IonToolbar className="titulo">
          <IonButtons slot="start">
            <IonBackButton defaultHref="mapa" />
          </IonButtons>
          <IonTitle className="titulo">Notícia</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <IonGrid className="planoFundo">
          <IonGrid className="PlanoNoticia">
            <IonGrid className="campoNoticia">

              <IonGrid className="noticia">Notícias</IonGrid>
              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/indicada-para-concorrer-ao-premio-da-onu-para-mulheres-empreendedoras-agda-encontrou-no-empreendedorismo-sua-formula-do-sucesso/">
                Indicada para concorrer ao prêmio da ONU para mulheres empreendedoras,
               Agda encontrou no empreendedorismo sua fórmula do sucesso</a>
              <a className="data">21/08/20 às 17h57 - Atualizado em 22/08/20 às 13h22</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/acordo-de-cooperacao-firmado-entre-a-secretaria-de-empreendedorismo-e-a-oab-df-seccional-taguatinga-oferecera-orientacao-empresarial-aos-empreendedores-que-buscam-os-servicos-do-simplifica-pj/">
                A Secretaria de Empreendedorismo e a OAB/DF, oferece aos empreendedores serviços jurídicos no Simplifica PJ</a>
              <a className="data">11/08/20 às 10h40 - Atualizado em 11/08/20 às 11h49</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/secretaria-de-empreendedorismo-do-df-assina-termo-de-cooperacao-com-o-brb/">
                Secretaria de Empreendedorismo do DF e BRB irão atuar juntos para fomentar novos negócios no DF
              </a>
              <a className="data">7/08/20 às 12h24 - Atualizado em 7/08/20 às 15h15</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/em-tempos-dificeis-por-conta-da-pandemia-a-inovacao-e-a-criatividade-tomam-conta-dos-micro-e-pequenos-empreendedores/">
                Em tempos difíceis, por conta da pandemia, a inovação e a criatividade tomam conta dos micro e pequenos empreendedores
             </a>
              <a className="data">6/08/20 às 13h16 - Atualizado em 6/08/20 às 13h21</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/unidade-de-apoio-a-mulher-empreendedora-oferece-apoio-juridico-e-psicologico-as-empresarias/">
                Unidade de Apoio à Mulher Empreendedora oferece apoio jurídico e psicológico às empresárias
              </a>
              <a className="data">31/07/20 às 17h35 - Atualizado em 21/08/20 às 17h03</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/empresarios-ja-podem-aderir-ao-desenvolve-df-a-partir-da-proxima-semana/">
                Empresários já podem aderir ao Desenvolve-DF a partir da próxima semana
              </a>
              <a className="data">30/07/20 às 13h40 - Atualizado em 3/08/20 às 20h24</a><br />

              <br />
              <a className="noticiaTexto" href="http://www.empreender.df.gov.br/empresas-do-pro-df-ii-ja-podem-regularizar-situacao/">
                Empresas do Pro-DF II já podem regularizar situação
              </a>
              <a className="data">23/07/20 às 17h26 - Atualizado em 3/08/20 às 18h26</a><br />

              <IonGrid className="verMais"  >
                <a className="data" href="http://www.empreender.df.gov.br/category/noticias/" >Ver mais</a>
              </IonGrid>
            </IonGrid>

          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Noticia;
