import React from 'react';
import {  IonPage, IonImg } from '@ionic/react';
import logoGdf from "../imagens/logoGdf.png"
export default function TelaInicial() {
  return (
    <IonPage mode="md" style={{
      flex: 1,
      backgroundColor: '#0061AE',
      paddingTop: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <IonImg style={{
        width: 360,
        height: 291,
        resizeMode: 'contain'
      }} src={logoGdf} />
      <ion-text >
        <h1 style={{
          
            color: 'white',
            fontFamily: 'Helvetica Neue',
            paddingTop: 5,
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '156%',
            
          }}
        >Secretaria de Estado de Empreendedorismo</h1>
      </ion-text>
      <ion-text >
        <h1 style={{
          color: 'white',
          fontFamily: 'Helvetica Neue',
          paddingTop: 0,
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: '156%',
        }}>SEMP</h1>
      </ion-text>
    </IonPage>
  );
};
