import React, { useEffect, useRef, useState } from 'react';
import {
    IonIcon,
    useIonViewDidEnter,
    IonFab,
    IonFabButton,
    IonFabList
} from '@ionic/react'
import { ellipsisHorizontal, locate, search } from 'ionicons/icons';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLng } from "leaflet";
import { Plugins } from "@capacitor/core";
import iconVerde from '../imagens/MapaIcon/IconMapaVerde.svg';
import iconAzul from '../imagens/MapaIcon/IconMapaAzul.svg';
import iconAmarelo from '../imagens/MapaIcon/IconMapaAmarelo.svg';


import 'leaflet/dist/leaflet.css'
// import { Console } from 'console';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

var greenIcon = L.icon({
    iconUrl: iconVerde,
    iconSize: [22, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [10, 67], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
});
var blueIcon = L.icon({
    iconUrl: iconAzul,
    iconSize: [26, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [10, 67], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
});
var yellowIcon = L.icon({
    iconUrl: iconAmarelo,
    iconSize: [28, 95], // [38, 95] size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [10, 67], // [18, 72] point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
});

var zoomMap = 10;

export default function Mapa(props) {

    const [verde, setVerde] = useState(true);
    const [azul, setAzul] = useState(true);
    const [amarelo, setAmarelo] = useState(true);
    // const [infoFiltrado, setInfoFiltrado] = useState();
    const inputEl = useRef(null);

    let { lat, lng, setLocal, local, setPesquisarRegiao, setComuta, setRegiaoADE, localPonto, setLocalPonto } = props;
    if (typeof zoomMap !== "number") {

        console.log("<<<<AaaaaaaaERROaaaaaaaA>>>>", typeof zoomMap);
        zoomMap = 10;
    }
    useIonViewDidEnter(function () {
        window.dispatchEvent(new Event('resize'));
    })


    useEffect(() => {
        if (local) {
            getCurrentLocation();
        } else if (localPonto) {
            getPontoMapa();
        }
    })
    function getPontoMapa() {
        // console.log(localPonto);
        window.dado.filter(item => {
            if (item["__3"] === localPonto) {
                // console.log(item["__3"], "lat= ", item["__15"]);
                lat = item["__15"];
                lng = item["__16"];
                zoomMap = 16;
                inputEl.current.leafletElement.flyTo(new LatLng(lat, lng));
                setLocalPonto('');
                return true;
            }
            return false;
        })
        // console.log(arr);
        // lat = result.coords.latitude;
        // lng = result.coords.longitude;
        // setLocal(false);
        // inputEl.current.leafletElement.flyTo(new LatLng(lat, lng));

    }
    function getCurrentLocation() {
        Plugins.Geolocation.getCurrentPosition().then(result => {
            lat = result.coords.latitude;
            lng = result.coords.longitude;
            zoomMap = 16;
            setLocal(false);
            inputEl.current.leafletElement.flyTo(new LatLng(lat, lng));
        });
    }

    function clickIcon(a) {
        // console.log(">>>>turubebes<<<<<", a );
        setComuta(false);
        setRegiaoADE(a);
        // window.location.href = "/compare";
    }
    
    return (
        <Map ref={inputEl} center={[lat, lng]} zoom={zoomMap} mode="md" >
            <TileLayer

                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {window.dado && window.dado.map((item, i) => (
                <div key={i} >
                    {(item["__1"] === "UF" && amarelo) &&
                        <Marker title="oi" key={i} position={[item["__15"], item["__16"]]} icon={yellowIcon}>
                            <Popup><a onClick={() => clickIcon(item["__3"])}>{item["__3"]}</a></Popup>
                        </Marker>
                    }
                    {(item["__1"] === "RA" && azul) &&
                        <Marker title="oi" key={i} position={[item["__15"], item["__16"]]} icon={blueIcon}>
                            <Popup><a onClick={() => clickIcon(item["__3"])}>{item["__3"]}</a></Popup>
                        </Marker>
                    }
                    {(item["__1"] === "ADE" && verde) &&
                        <Marker title="oi" key={i} position={[item["__15"], item["__16"]]} icon={greenIcon}>
                            <Popup><a onClick={() => clickIcon(item["__3"])}>{item["__3"]}</a></Popup>
                        </Marker>
                    }
                </div>
            ))
            }
            {/* { verde &&
                markersAzul.map((m, i) => (
                    <Marker key={i} position={[m.lat, m.lng]} icon={greenIcon} onClick={() => clickIcon()} />
                ))
            }
            { amarelo &&
                markersAmarelo.map((m, i) => (
                    <Marker key={i} position={[m.lat, m.lng]} icon={yellowIcon} onClick={() => clickIcon()} />
                ))<IonFabButton onClick={() => setVerde(!verde)}>
                        <IonIcon icon={iconVerde} />
                    </IonFabButton>
            } */}

            <IonFab vertical="bottom" horizontal="end" slot="fixed" >
                <IonFabButton>
                    <IonIcon icon={ellipsisHorizontal} />
                </IonFabButton>
                <IonFabList side="top">
                    <IonFabButton onClick={() => setLocal(true)}>
                        <IonIcon icon={locate} />
                    </IonFabButton>
                    <IonFabButton onClick={() => setPesquisarRegiao(true)} >
                        <IonIcon icon={search} />
                    </IonFabButton>
                    <IonFabButton onClick={() => setVerde(!verde)}>
                        <IonIcon icon={iconVerde} />
                    </IonFabButton>
                    <IonFabButton onClick={() => setAzul(!azul)}>
                        <IonIcon icon={iconAzul} />
                    </IonFabButton>
                    <IonFabButton onClick={() => setAmarelo(!amarelo)}>
                        <IonIcon icon={iconAmarelo} />
                    </IonFabButton>
                </IonFabList>
            </IonFab>
        </Map>
    )
}
