
export function FormataMoeda(valor) {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}
export function CriarCompare(json) {
    // console.log('>>>CriarCompare<<<', json );

    let arr = [];
    let total = {
        gerenciado: 0,
        implantado: 0,
        concedida: 0,
        edificada: 0,
        aSerEdificada: 0,
        investimento: 0,
        vImovel: 0,
        descontos: 0,
        empregoAtual: 0,
        aSerGerado: 0,
        empregoTotal: 0
    }
    // if (localStorage.getItem("compareArray") === null) {
        json.forEach((item, index) => {

            if (index > 0 && index < 20) {

                // console.log(index, item);
                let uf = item["__1"];
                let nome = item["__3"];
                let gerenciado = parseInt(item["__4"]);
                let implantado = parseInt(item["__5"]);
                let concedida = item["__6"];
                let edificada = item["__7"];
                let aSerEdificada = item["__8"] + "";
                let investimento = item["__9"];
                let vImovel = item["__10"] + "";
                let descontos = item["__11"];
                let empregoAtual = item["__12"] + "";
                let aSerGerado = item["__13"] + "";
                let empregoTotal = item["__14"] + "";

                concedida = parseInt(concedida.replace(/\./g, '').replace(",", '.'));
                edificada = parseInt(edificada.replace(/\./g, '').replace(",", '.'));
                // edificada = parseInt(edificada.replace(/\./g, '').replace(",", '.'));
                investimento = investimento.replace("R$", '').trim();
                investimento = parseFloat(investimento.replace(/\./g, '').replace(",", '.'));

                vImovel = vImovel.replace("R$", '').trim();
                vImovel = parseFloat(vImovel.replace(/\./g, '').replace(",", '.'));

                descontos = descontos.replace("R$", '').trim();
                descontos = parseFloat(descontos.replace(/\./g, '').replace(",", '.'));

                aSerEdificada = parseInt(aSerEdificada.replace(/\./g, '').replace(",", '.'));

                // empregoAtual = parseInt(empregoAtual.replace(/\./g, '').replace(",", '.'));
                // empregoTotal = parseInt(empregoTotal.replace(/\./g, '').replace(",", '.'));
                if (typeof empregoAtual === 'string') {
                    empregoAtual = parseInt(empregoAtual.replace(/\./g, '').replace(",", '.'));
                } else {
                    empregoAtual = parseInt(empregoAtual);
                }
                if (typeof aSerGerado === 'string') {
                    aSerGerado = parseInt(aSerGerado.replace(/\./g, '').replace(",", '.'));
                    // aSerGerado = 0;
                } else {
                    aSerGerado = parseInt(aSerGerado);
                }
                if (typeof empregoTotal === 'string') {
                    empregoTotal = parseInt(empregoTotal.replace(/\./g, '').replace(",", '.'));
                } else {
                    empregoTotal = parseInt(empregoTotal);
                }

                let valor = arr.find(v => v.nome === nome);
                gerenciado = !isNaN(gerenciado) ? gerenciado : 0;
                implantado = !isNaN(implantado) ? implantado : 0;
                concedida = !isNaN(concedida) ? concedida : 0;
                edificada = !isNaN(edificada) ? edificada : 0;
                aSerEdificada = !isNaN(aSerEdificada) ? aSerEdificada : 0;

                investimento = !isNaN(investimento) ? investimento : 0;
                vImovel = !isNaN(vImovel) ? vImovel : 0;
                descontos = !isNaN(descontos) ? descontos : 0;

                empregoAtual = !isNaN(empregoAtual) ? empregoAtual : 0;
                aSerGerado = !isNaN(aSerGerado) ? aSerGerado : 0;
                empregoTotal = !isNaN(empregoTotal) ? empregoTotal : 0;

                if (valor) {
                    valor.gerenciado += gerenciado;
                    valor.implantado += implantado;
                    valor.concedida += concedida;
                    valor.edificada += edificada;
                    valor.aSerEdificada += aSerEdificada;
                    valor.investimento += investimento;
                    valor.vImovel += vImovel;
                    valor.descontos += descontos;
                    valor.empregoAtual += empregoAtual;
                    valor.aSerGerado += (aSerGerado);
                    valor.empregoTotal += (empregoTotal);

                } else {
                    arr.push({
                        uf: uf,
                        nome: nome,
                        gerenciado: gerenciado,
                        implantado: implantado,
                        concedida: concedida,
                        edificada: edificada,
                        aSerEdificada: aSerEdificada,
                        investimento: investimento,

                        vImovel: vImovel,
                        descontos: descontos,
                        empregoAtual: empregoAtual,
                        aSerGerado: (aSerGerado),
                        empregoTotal: (empregoTotal),
                    })
                }
                total.gerenciado += gerenciado;
                total.implantado += implantado;
                total.concedida += concedida;
                total.edificada += edificada;
                total.aSerEdificada += aSerEdificada;
                total.investimento += investimento;

                total.vImovel += vImovel;
                total.descontos += descontos;
                total.empregoAtual += empregoAtual;
                total.aSerGerado += (aSerGerado);
                total.empregoTotal += (empregoTotal);

                // console.log('>>>total.aSerGerado<<<', nome, aSerGerado, empregoTotal );
            }
        });
        localStorage.setItem("compareArray", JSON.stringify(arr));
        localStorage.setItem("compareTotal", JSON.stringify(total));
    // } else {
    //     arr = JSON.parse(localStorage.getItem("compareArray"));
    //     total = JSON.parse(localStorage.getItem("compareTotal"));
    // }
    window.compareArray = arr;
    window.compareTotal = total;
}